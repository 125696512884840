body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(11, 5, 4, 0.1)
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(11, 5, 4, 0.1)
  );  */
}

h3 {
  font-size: 24px;
}

h5 {
  margin: 0;
  font-size: 16px;
}
